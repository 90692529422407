<template>
	<div class="task-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
		<b-modal v-model="modal" centered hide-header-close
		         ok-variant="danger"
		         cancel-variant="custom-light"
		         v-bind:ok-disabled="!password || !taskNo"
		         v-on:ok="deleteTask"
		         v-on:hide="resetModal">
			<h3 slot="modal-title">Delete Task</h3>
			<h5 slot="modal-ok" class="m-0">Confirm</h5>
			<h5 slot="modal-cancel" class="m-0">Cancel</h5>
			<form action="" class="container-modal-form position-relative w-100 h-100 p-3">
				<div class="form-group position-relative">
					<label for="password" class="font-weight-bold">Password</label>
					<input type="password" id="password" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': pwInvalid}"
					       v-model="password"
					       v-on:input="pwInvalid = false">
				</div>
				<div class="form-group position-relative">
					<label for="modalTaskNo" class="font-weight-bold">Task No.</label>
					<input type="text" id="modalTaskNo" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': taskNoInvalid}"
					       v-model="taskNo"
					       v-on:input="taskNoInvalid = false">
				</div>
			</form>
		</b-modal>
		<div class="details position-relative w-50 h-100 rounded bg-white d-flex flex-column align-items-center flex-grow-0 overflow-hidden">
			<form-controls v-bind:msg="msg"
			               v-bind:msg-invalid="msgInvalid">
				<control-button slot="left"
				                v-bind:disabled="false"
				                v-bind:btn-title="backBtnTitle"
				                v-bind:btn-icon="['fas', 'arrow-left']"
				                v-bind:btn-class="backBtnClass"
				                v-on:btn-pressed="goBack"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!savable"
				                btn-title="SAVE"
				                v-bind:btn-icon="['fas', 'hdd']"
				                btn-class="btn-custom-light ml-2"
				                v-on:btn-pressed="saveTask"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!task"
				                btn-title="DELETE"
				                v-bind:btn-icon="['fas', 'trash-alt']"
				                btn-class="btn-danger ml-2"
				                v-on:btn-pressed="modal = true"></control-button>
			</form-controls>
			<form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
				<div class="form-group position-relative w-100">
					<label for="taskNo" class="font-weight-bold">Task No.</label>
					<input type="text" id="taskNo" placeholder=""
					       class="form-control position-relative"
					       v-bind:value="localTask.taskNo" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="stage" class="font-weight-bold">Stage</label>
					<select id="stage" class="form-control position-relative"
					        v-bind:class="invalidInputs.stage"
					        v-bind:value="localTask.stage"
					        v-on:change="updateStage($event.target.value)">
						<option disabled hidden value=""></option>
						<option>lead</option>
						<option>opportunity</option>
						<option>sales</option>
						<option>campaign</option>
					</select>
				</div>
				<div class="form-group position-relative w-100">
					<label for="status" class="font-weight-bold">Status</label>
					<select id="status" class="form-control position-relative"
					        v-bind:class="invalidInputs.status"
					        v-bind:value="localTask.status"
					        v-on:change="updateStatus($event.target.value)">
						<option disabled hidden value=""></option>
						<option>open</option>
						<option>closed</option>
					</select>
				</div>
                <div class="form-group position-relative w-100">
                    <label for="budget" class="font-weight-bold">Project Budget</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">HK$</span>
                        </div>
                        <input type="text" id="budget" class="form-control position-relative" placeholder=""
                               v-bind:value="localTask.budget">
                    </div>
                </div>
                <div class="form-group position-relative w-100">
                    <label for="cost" class="font-weight-bold">Project Cost</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">HK$</span>
                        </div>
                        <input type="text" id="cost" class="form-control position-relative"
                               placeholder="Automatically calculated when purchases are appended"
                               v-bind:value="localTask.cost" readonly>
                    </div>
                </div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="dueAtDate" class="font-weight-bold text-truncate">Due Date</label>
						<b-form-datepicker id="dueAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.dueAtDT.date"
						                   v-bind:value="localTask.dueAtDT.date"
						                   v-on:input="updateDT('dueAt', 'dueAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="dueAtTime" class="font-weight-bold text-truncate">Due Time</label>
						<b-form-timepicker id="dueAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.dueAtDT.time"
						                   v-bind:value="localTask.dueAtDT.time"
						                   v-on:input="updateDT('dueAt', 'dueAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="handler" class="font-weight-bold">Handler</label>
					<input type="text" id="handler" class="form-control position-relative"
					       placeholder="Choose A Handler Among Your Staffs"
					       v-bind:class="invalidInputs.handler"
					       v-bind:value="localTask.handlerName" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="client" class="font-weight-bold">Client</label>
					<input type="text" id="client" class="form-control position-relative"
					       placeholder="Choose Your Intended Client From The Table"
					       v-bind:class="invalidInputs.client"
					       v-bind:value="localTask.clientName" readonly>
				</div>
				<div class="container-form-group position-relative w-100">
					<h6 class="position-relative mb-3 font-weight-bold">Quotation Requests</h6>
					<div v-if="localTask.supplierQuotationsNo.length === 0"
					     class="form-group position-relative w-100 d-flex align-items-center">
						<label for="quotationRequest" class="mb-0 mr-3 font-weight-bold">1</label>
						<input type="text" id="quotationRequest" placeholder="Choose Quotation Request(s) From The Table"
						       class="form-control position-relative"
						       value="" readonly>
					</div>
					<div class="form-group position-relative w-100 d-flex align-items-center"
					     v-for="(request, index) in localTask.quotationRequestsNo"
					     v-bind:key="request">
						<label v-bind:for="`quotationRequest${index}`" class="mb-0 mr-3 font-weight-bold">{{ index + 1 }}</label>
						<input type="text" v-bind:id="`quotationRequest${index}`" placeholder=""
						       class="form-control position-relative"
						       v-bind:value="request" readonly>
					</div>
				</div>
				<div class="container-form-group position-relative w-100">
					<h6 class="position-relative mb-3 font-weight-bold">Supplier Quotes</h6>
					<div v-if="localTask.supplierQuotationsNo.length === 0"
					     class="form-group position-relative w-100 d-flex align-items-center">
						<label for="supplierQuote" class="mb-0 mr-3 font-weight-bold">1</label>
						<input type="text" id="supplierQuote" placeholder="Choose Supplier Quote(s) From The Table"
						       class="form-control position-relative"
						       value="" readonly>
					</div>
					<div class="form-group position-relative w-100 d-flex align-items-center"
					     v-for="(quote, index) in localTask.supplierQuotationsNo"
					     v-bind:key="quote">
						<label v-bind:for="`supplierQuote${index}`" class="mb-0 mr-3 font-weight-bold">{{ index + 1 }}</label>
						<input type="text" v-bind:id="`supplierQuote${index}`" placeholder=""
						       class="form-control position-relative"
						       v-bind:value="quote" readonly>
					</div>
				</div>
				<div class="container-form-group position-relative w-100">
					<h6 class="position-relative mb-3 font-weight-bold">Purchase Orders</h6>
					<div v-if="localTask.purchaseOrdersNo.length === 0"
					     class="form-group position-relative w-100 d-flex align-items-center">
						<label for="purchaseOrder" class="mb-0 mr-3 font-weight-bold">1</label>
						<input type="text" id="purchaseOrder" placeholder="Choose Purchase Order(s) From The Table"
						       class="form-control position-relative"
						       value="" readonly>
					</div>
					<div class="form-group position-relative w-100 d-flex align-items-center"
					     v-for="(order, index) in localTask.purchaseOrdersNo"
					     v-bind:key="order">
						<label v-bind:for="`po${index}`" class="mb-0 mr-3 font-weight-bold">{{ index + 1 }}</label>
						<input type="text" v-bind:id="`po${index}`" placeholder=""
						       class="form-control position-relative"
						       v-bind:value="order" readonly>
					</div>
				</div>
				<div class="container-form-group position-relative w-100">
					<h6 class="position-relative mb-3 font-weight-bold">Sales Quotes</h6>
					<div v-if="localTask.salesQuotationsNo.length === 0"
					     class="form-group position-relative w-100 d-flex align-items-center">
						<label for="salesQuote" class="mb-0 mr-3 font-weight-bold">1</label>
						<input type="text" id="salesQuote" placeholder="Choose Sales Quote(s) From The Table"
						       class="form-control position-relative"
						       value="" readonly>
					</div>
					<div class="form-group position-relative w-100 d-flex align-items-center"
					     v-for="(quote, index) in localTask.salesQuotationsNo"
					     v-bind:key="quote">
						<label v-bind:for="`salesQuote${index}`" class="mb-0 mr-3 font-weight-bold">{{ index + 1 }}</label>
						<input type="text" v-bind:id="`salesQuote${index}`" placeholder=""
						       class="form-control position-relative"
						       v-bind:value="quote" readonly>
					</div>
				</div>
				<div class="container-form-group position-relative w-100">
					<h6 class="position-relative mb-3 font-weight-bold">Sales Orders</h6>
					<div v-if="localTask.salesOrdersNo.length === 0"
					     class="form-group position-relative w-100 d-flex align-items-center">
						<label for="salesOrder" class="mb-0 mr-3 font-weight-bold">1</label>
						<input type="text" id="salesOrder" placeholder="Choose Sales Order(s) From The Table"
						       class="form-control position-relative"
						       value="" readonly>
					</div>
					<div class="form-group position-relative w-100 d-flex align-items-center"
					     v-for="(order, index) in localTask.salesOrdersNo"
					     v-bind:key="order">
						<label v-bind:for="`salesOrder${index}`" class="mb-0 mr-3 font-weight-bold">{{ index + 1 }}</label>
						<input type="text" v-bind:id="`salesOrder${index}`" placeholder=""
						       class="form-control position-relative"
						       v-bind:value="order" readonly>
					</div>
				</div>
				<div class="container-form-group position-relative w-100">
					<h6 class="position-relative mb-3 font-weight-bold">Campaigns</h6>
					<div v-if="localTask.campaignsNo.length === 0"
					     class="form-group position-relative w-100 d-flex align-items-center">
						<label for="campaign" class="mb-0 mr-3 font-weight-bold">1</label>
						<input type="text" id="campaign" placeholder="Choose Campaign(s) From The Table"
						       class="form-control position-relative"
						       value="" readonly>
					</div>
					<div class="form-group position-relative w-100 d-flex align-items-center"
					     v-for="(campaign, index) in localTask.campaignsNo"
					     v-bind:key="campaign">
						<label v-bind:for="`campaign${index}`" class="mb-0 mr-3 font-weight-bold">{{ index + 1 }}</label>
						<input type="text" v-bind:id="`campaign${index}`" placeholder=""
						       class="form-control position-relative"
						       v-bind:value="campaign" readonly>
					</div>
				</div>

                <div class="mb-3">
                    <div class="d-flex align-items-center flex-nowrap mb-2">
                        <h6 class="position-relative mb-0 flex-grow-1 font-weight-bold">
                            Tasklets
                        </h6>
                        <button class="btn btn-sm btn-info text-uppercase font-weight-bolder rounded-pill py-1"
                                v-on:click="addTasklet">
                            <font-awesome-icon icon="plus"></font-awesome-icon> Add
                        </button>
                    </div>

                    <ul class="list-group">
                        <li class="list-group-item overflow-hidden p-0"
                            v-for="tasklet in localTask.tasklets"
                            v-bind:key="tasklet._id">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text border-0 rounded-0">
                                        <input type="checkbox"
                                               v-model="tasklet.done">
                                    </div>
                                </div>
                                <input type="text"
                                       class="form-control border-0"
                                       placeholder="Fill me"
                                       v-model="tasklet.text">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-danger border-0 rounded-0"
                                            type="button"
                                            v-on:click="deleteTasklet(tasklet._id)">
                                        <font-awesome-icon icon="trash"></font-awesome-icon>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

				<div class="form-group position-relative w-100">
					<label for="notes" class="font-weight-bold">Notes</label>
					<textarea id="notes" rows="6" class="form-control position-relative"
					          v-model="localTask.notes"></textarea>
				</div>
				<div class="form-group position-relative w-100">
					<label for="updatedBy" class="font-weight-bold">Task Updated By</label>
					<input type="text" id="updatedBy" class="form-control position-relative"
					       placeholder="Task Not Yet Saved"
					       v-bind:value="localTask.updatedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="updatedAtDate" class="font-weight-bold text-truncate">Date Updated</label>
						<b-form-datepicker id="updatedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localTask.updatedAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="updatedAtTime" class="font-weight-bold text-truncate">Time Updated</label>
						<b-form-timepicker id="updatedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localTask.updatedAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="createdBy" class="font-weight-bold">Task Created By</label>
					<input type="text" id="createdBy" class="form-control position-relative"
					       placeholder="Task Not Yet Created"
					       v-bind:value="localTask.createdByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="createdAtDate" class="font-weight-bold text-truncate">Date Created</label>
						<b-form-datepicker id="createdAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localTask.createdAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="createdAtTime" class="font-weight-bold text-truncate">Time Created</label>
						<b-form-timepicker id="createdAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localTask.createdAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
			</form>
		</div>
		<div class="table-selects scroll-bar position-relative w-50 h-100 ml-3 rounded">
			<table-select title="Staff" filter="name" sort="name"
			              v-bind:order="1"
			              v-bind:fields="staffsFields"
			              v-bind:data-array="staffs"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localTask.handler]"
			              v-on:row-clicked="selectHandler($event)"
			              v-bind:style="{height: '50% !important'}">
            </table-select>
			<table-select title="Client" filter="name" sort="name"
			              v-bind:order="1"
			              v-bind:fields="clientsFields"
			              v-bind:data-array="clients"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localTask.client]"
			              v-on:row-clicked="selectClient($event)"
			              v-bind:style="{height: '50% !important', marginTop: '16px'}">
            </table-select>
			<table-select title="Quotation Request" filter="requestNo" sort="issuedAt"
			              v-bind:order="-1"
			              v-bind:fields="quotationRequestFields"
			              v-bind:data-array="quotationRequests"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="true"
			              v-bind:selected-ids="localTask.quotationRequests"
			              v-on:row-clicked="multiSelect('quotationRequests', $event, 'requestNo')"
			              v-bind:style="{height: '50% !important', marginTop: '16px'}">
            </table-select>
			<table-select title="Supplier Quote" filter="quoteNo" sort="quoteNo"
			              v-bind:order="1"
			              v-bind:fields="supplierQuoteFields"
			              v-bind:data-array="supplierQuotes"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="true"
			              v-bind:selected-ids="localTask.supplierQuotations"
			              v-on:row-clicked="multiSelect('supplierQuotations', $event, 'quoteNo')"
			              v-bind:style="{height: '50% !important', marginTop: '16px'}">
            </table-select>
			<table-select title="Purchase Order" filter="poNo" sort="poNo"
			              v-bind:order="1"
			              v-bind:fields="purchaseOrderFields"
			              v-bind:data-array="purchaseOrders"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="true"
			              v-bind:selected-ids="localTask.purchaseOrders"
			              v-on:row-clicked="multiSelect('purchaseOrders', $event, 'poNo')"
			              v-bind:style="{height: '50% !important', marginTop: '16px'}">
            </table-select>
			<table-select title="Sales Quote" filter="quoteNo" sort="quoteNo"
			              v-bind:order="1"
			              v-bind:fields="salesQuoteFields"
			              v-bind:data-array="salesQuotes"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="true"
			              v-bind:selected-ids="localTask.salesQuotations"
			              v-on:row-clicked="multiSelect('salesQuotations', $event, 'quoteNo')"
			              v-bind:style="{height: '50% !important', marginTop: '16px'}">
            </table-select>
			<table-select title="Sales Order" filter="orderNo" sort="orderNo"
			              v-bind:order="1"
			              v-bind:fields="salesOrderFields"
			              v-bind:data-array="salesOrders"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="true"
			              v-bind:selected-ids="localTask.salesOrders"
			              v-on:row-clicked="multiSelect('salesOrders', $event, 'orderNo')"
			              v-bind:style="{height: '50% !important', marginTop: '16px'}">
            </table-select>
			<table-select title="Campaign" filter="campaignNo" sort="beginAt"
			              v-bind:order="-1"
			              v-bind:fields="campaignFields"
			              v-bind:data-array="campaigns"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="true"
			              v-bind:selected-ids="localTask.campaigns"
			              v-on:row-clicked="multiSelect('campaigns', $event, 'campaignNo')"
			              v-bind:style="{height: '50% !important', marginTop: '16px'}">
            </table-select>
		</div>
	</div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import TableSelect from "@/components/functional/table/TableSelect";
import ControlButton from "@/components/functional/form/ControlButton";
import { v4 as uuidv4 } from 'uuid';
import { BModal, BFormDatepicker, BFormTimepicker } from "bootstrap-vue";
import { format } from "date-fns"
import { dateTimeDifferent } from "@/utility/helpers";

export default {
	name: "TaskDetails",
	components: {
		FormControls,
		TableSelect,
		ControlButton,
		BModal,
		BFormDatepicker,
		BFormTimepicker
	},
	props: {
		// props from vue router route params
		taskId: {
			type: String,
		}
	},
	beforeRouteLeave(to, from, next) {
		this.modal = false;
		if (this.savable) {
			if (this.msg === "Leave Without Saving?") {
				// user has already been notified
				next();
			} else {
				this.toRoute = to.fullPath;
				this.msg = "Leave Without Saving?";
				this.msgInvalid = true;
				this.backBtnTitle = "YES!";
				this.backBtnClass = "btn-danger mr-2";
			}
		} else {
			next();
		}
	},
	created() {
		this.initTask();
		window.addEventListener("beforeunload", this.unloadHandler);
	},
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.unloadHandler);
	},
	data() {
		return {
			localTask: {
				_id: "",
				taskNo: "",
				status: "",
				stage: "",
				client: "",
				quotationRequests: [],
				supplierQuotations: [],
				purchaseOrders: [],
				salesQuotations: [],
				salesOrders: [],
				campaigns: [],
				handler: "",
				dueAt: "",
				createdAt: "",
				updatedAt: "",
				createdBy: "",
				updatedBy: "",
				notes: "",
				// added properties
				clientName: "",
				handlerName: "",
				quotationRequestsNo: [],
				supplierQuotationsNo: [],
				purchaseOrdersNo: [],
				salesQuotationsNo: [],
				salesOrdersNo: [],
				campaignsNo: [],
                tasklets: [
                    {
                        _id: "teaadsda",
                        done: false,
                        text: "Contact client",
                    },
                    {
                        _id: "dasd",
                        done: false,
                        text: "Contact handlers",
                    }
                ],
				createdByName: "",
				updatedByName: "",
				dueAtDT: { date: "", time: "" },
				createdAtDT: { date: "", time: "" },
				updatedAtDT: { date: "", time: "" },
			},
			// use v-model on input that does not require validation
			invalidInputs: {
				status: "",
				stage: "",
				client: "",
				handler: "",
				dueAtDT: { date: "", time: "" },
			},
			clientsFields: [
				{ display: "Client No.", key: "clientNo", classBinding: ["f-3"] },
				{ display: "Name", key: "name", classBinding: ["f-4"] },
				{ display: "Type", key: "type", classBinding: ["f-2"] },
			],
			staffsFields: [
				{ display: "Name", key: "name", classBinding: ["f-4"] },
				{ display: "Staff No.", key: "staffNo", classBinding: ["f-3"] },
				{ display: "Gender", key: "gender", classBinding: [] },
				{ display: "Position", key: "position", classBinding: ["f-4"] },
			],
			quotationRequestFields: [
				{ display: "Request No.", key: "requestNo", classBinding: ["f-3"] },
				{ display: "Supplier", key: "supplier", classBinding: ["f-4"] },
				{ display: "Items", key: "items", classBinding: [] },
				{ display: "Issued At", key: "issuedAt", classBinding: [] },
			],
			supplierQuoteFields: [
				{ display: "Quote No.", key: "quoteNo", classBinding: ["f-3"] },
				{ display: "Supplier", key: "supplier", classBinding: ["f-4"] },
				{ display: "Items", key: "items", classBinding: [] },
				{ display: "Currency", key: "currency", classBinding: [] },
				{ display: "Total", key: "totalAmount", classBinding: ["f-2"] },
			],
			purchaseOrderFields: [
				{ display: "Order No.", key: "poNo", classBinding: ["f-3"] },
				{ display: "Supplier", key: "supplier", classBinding: ["f-4"] },
				{ display: "Items", key: "items", classBinding: [] },
				{ display: "Currency", key: "currency", classBinding: [] },
				{ display: "Total", key: "totalAmount", classBinding: ["f-2"] },
			],
			salesQuoteFields: [
				{ display: "Quote No.", key: "quoteNo", classBinding: ["f-3"] },
				{ display: "Client", key: "client", classBinding: ["f-4"] },
				{ display: "Items", key: "items", classBinding: [] },
				{ display: "Currency", key: "currency", classBinding: [] },
				{ display: "Total", key: "totalAmount", classBinding: ["f-2"] },
			],
			salesOrderFields: [
				{ display: "Order No.", key: "orderNo", classBinding: ["f-3"] },
				{ display: "Client", key: "client", classBinding: ["f-4"] },
				{ display: "Items", key: "items", classBinding: [] },
				{ display: "Currency", key: "currency", classBinding: [] },
				{ display: "Total", key: "totalAmount", classBinding: ["f-2"] },
			],
			campaignFields: [
				{ display: "Campaign No.", key: "campaignNo", classBinding: ["f-2"] },
				{ display: "Name", key: "name", classBinding: ["f-3"] },
				{ display: "Client", key: "client", classBinding: ["f-3"] },
				{ display: "Begin", key: "beginAt", classBinding: [] },
				{ display: "End", key: "endAt", classBinding: [] },
			],
			msg: "",
			msgInvalid: false,
			modal: false,
			toRoute: "",
			backBtnTitle: "BACK",
			backBtnClass: "btn-custom-light mr-2",
			password: "",
			pwInvalid: false,
			taskNo: "",
			taskNoInvalid: false,
		}
	},

	computed: {
		task() {
			return this.$store.state.contents.tasks.find(({ _id }) => _id === this.taskId);
		},

		savable() {
			// fall through pattern
			if (!this.task) return true;
			// can safely access properties inside this.task due to the first if clause in this function
			if (dateTimeDifferent(this.localTask.dueAt, this.task.dueAt)) return true;
			const arrKeys = ["quotationRequests", "supplierQuotations", "purchaseOrders", "salesQuotations", "salesOrders", "campaigns"];
			if (arrKeys.some(key => this.arrDifferent(this.localTask[key], this.task[key]))) return true;
			const invoiceKeys = ["status", "stage", "client", "handler", "notes"];
			return invoiceKeys.some(key => this.localTask[key] !== this.task[key]);
		},

		clients() {
			return this.$store.state.contents.clients.map(({ _id, name, clientNo, type }) => {
				return {
					_id: _id,
					name: name,
					clientNo: clientNo,
					type: type
				}
			});
		},

		staffs() {
			return this.$store.state.contents.staffs.map(({ _id, firstName, lastName, preferredName, staffNo, position, gender }) => {
				return {
					_id: _id,
					name: `${lastName} ${firstName}, ${preferredName}`,
					staffNo: staffNo,
					gender: gender,
					position: position
				};
			});
		},

		quotationRequests() {
			return this.$store.state.contents.quotationRequests.map(({ _id, requestNo, supplier, items, issuedAt }) => {
				const supplierFound = this.$store.state.contents.suppliers.find(({ _id }) => _id === supplier);
				return {
					_id: _id,
					requestNo: requestNo,
					supplier: supplierFound ? `${supplierFound.name} (${supplierFound.supplierNo})` : "N/A",
					items: items.length,
					issuedAt: issuedAt ? format(new Date(issuedAt), "yyyy-MM-dd") : "N/A",
				}
			});
		},

		supplierQuotes() {
			return this.$store.state.contents.supplierQuotations.map(({ _id, quoteNo, supplier, items, currency, totalAmount}) => {
				const supplierFound = this.$store.state.contents.suppliers.find(({ _id }) => _id === supplier);
				return {
					_id: _id,
					quoteNo: quoteNo,
					supplier: supplierFound ? `${supplierFound.name} (${supplierFound.supplierNo})` : "N/A",
					items: items.length,
					currency: currency,
					totalAmount: totalAmount
				}
			});
		},

		purchaseOrders() {
			return this.$store.state.contents.purchaseOrders.map(({ _id, poNo, supplier, items, currency, totalAmount }) => {
				const supplierFound = this.$store.state.contents.suppliers.find(({ _id }) => _id === supplier);
				return {
					_id: _id,
					poNo: poNo,
					supplier: supplierFound ? `${supplierFound.name} (${supplierFound.supplierNo})` : "N/A",
					items: items.length,
					currency: currency,
					totalAmount: totalAmount
				}
			});
		},

		salesQuotes() {
			return this.$store.state.contents.salesQuotations.map(({ _id, quoteNo, client, items, currency, totalAmount}) => {
				const clientFound = this.$store.state.contents.clients.find(({ _id }) => _id === client);
				return {
					_id: _id,
					quoteNo: quoteNo,
					client: clientFound ? `${clientFound.name} (${clientFound.clientNo})` : "N/A",
					items: items.length,
					currency: currency,
					totalAmount: totalAmount
				}
			});
		},

		salesOrders() {
			return this.$store.state.contents.salesOrders.map(({ _id, orderNo, client, items, currency, totalAmount }) => {
				const clientFound = this.$store.state.contents.clients.find(({ _id }) => _id === client);
				return {
					_id: _id,
					orderNo: orderNo,
					client: clientFound ? `${clientFound.name} (${clientFound.clientNo})` : "N/A",
					items: items.length,
					currency: currency,
					totalAmount: totalAmount
				}
			});
		},

		campaigns() {
			return this.$store.state.contents.campaigns.map(({ _id, campaignNo, client, name, beginAt, endAt }) => {
				const clientFound = this.$store.state.contents.clients.find(({ _id }) => _id === client);
				return {
					_id: _id,
					campaignNo: campaignNo,
					name: name,
					client: clientFound ? `${clientFound.name} (${clientFound.clientNo})` : "N/A",
					beginAt: format(new Date(beginAt), "yyyy-MM-dd"),
					endAt: format(new Date(endAt), "yyyy-MM-dd"),
				}
			});
		},
	},

	methods: {
	    addTasklet () {
	        this.localTask.tasklets
                .push(
                    {
                        _id: `tasklet-${ Math.random() }`,
                        done: false,
                        text: "",
                    }
                );
        },

        deleteTasklet (targetId) {
            this.localTask.tasklets.splice(
                this.localTask.tasklets.findIndex(
                    ({ _id }) => _id === targetId
                ),
                1
            );
        },

		unloadHandler(event) {
			if (this.savable) {
				event.preventDefault();
				event.returnValue = '';
			}
		},

		resetModal(bvModalEvent) {
			if (bvModalEvent.trigger !== "ok") {
				this.password = "";
				this.pwInvalid = false;
				this.taskNo = "";
				this.taskNoInvalid = false;
			}
		},

		arrDifferent(arr1, arr2) {
			// if arr2 contains an exact match, return false to keep checking the next contact
			if (arr1.length === arr2.length) return arr1.some(string1 => !arr2.includes(string1));
			return true;
		},

		initTask() {
			if (this.task) {
				const obj = {...this.task};
				const arrKeys = ["quotationRequests", "supplierQuotations", "purchaseOrders", "salesQuotations", "salesOrders", "campaigns"];
				arrKeys.forEach(key => {
					obj[key] = [...this.task[key]];
					const propName = key + "No";
					obj[propName] = [];
					let noKey = "";
					switch (key) {
						case "quotationRequests":
							noKey = "requestNo";
							break;
						case "supplierQuotations":
						case "salesQuotations":
							noKey = "quoteNo";
							break;
						case "purchaseOrders":
							noKey = "poNo";
							break;
						case "salesOrders":
							noKey = "orderNo";
							break;
						case "campaigns":
							noKey = "campaignNo";
							break;
					}
					obj[key].forEach(id => {
						const found = this.$store.state.contents[key].find(({ _id }) => _id === id);
						if (found) {
							obj[propName].push(found[noKey]);
						} else {
							obj[propName].push("N/A");
						}
					});
				});
				const client = this.$store.state.contents.clients.find(({ _id }) => _id === obj.client);
				const handler = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.handler);
				const createdBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.createdBy);
				const updatedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.updatedBy);
				// dueAt, receivedAt, createdAt and updatedAt must present in the database, need no nullish check
				const dueAtDT = new Date(obj.dueAt);
				const createdAtDT = new Date(obj.createdAt);
				const updatedAtDT = new Date(obj.updatedAt);
				// added properties
				obj.clientName = client ? `${client.name} (${client.clientNo})` : "";
				obj.handlerName = handler ? `${handler.lastName} ${handler.firstName}, ${handler.preferredName} (${handler.staffNo})` : "N/A";
				obj.createdByName = createdBy ? `${createdBy.lastName} ${createdBy.firstName}, ${createdBy.preferredName} (${createdBy.staffNo})` : "N/A";
				obj.updatedByName = updatedBy ? `${updatedBy.lastName} ${updatedBy.firstName}, ${updatedBy.preferredName} (${updatedBy.staffNo})` : "N/A";
				obj.dueAtDT = { date: format(dueAtDT, "yyyy-MM-dd"), time: format(dueAtDT, "HH:mm:ss")};
				obj.createdAtDT = { date: format(createdAtDT, "yyyy-MM-dd"), time: format(createdAtDT, "HH:mm:ss")};
				obj.updatedAtDT = { date: format(updatedAtDT, "yyyy-MM-dd"), time: format(updatedAtDT, "HH:mm:ss")};
				// auto closing
				if (new Date(obj.dueAt).getTime() <= Date.now()) {
					obj.status = "closed";
					this.$store.dispatch("contents/updateContentAttribute",
						{ key: "tasks", _id: this.task._id, attribute: "status", value: "closed" });
				}
				this.localTask = obj;
			} else {
				this.localTask._id = uuidv4();
				this.localTask.taskNo = "TAS-" + this.localTask._id.split("-")[0].toUpperCase();
			}
		},

		selectClient(client) {
			this.localTask.client = client._id;
			this.localTask.clientName = `${client.name} (${client.clientNo})`;
			this.invalidInputs.client = "";
		},

		selectHandler(staff) {
			this.localTask.handler = staff._id;
			this.localTask.handlerName = `${staff.name} (${staff.staffNo})`;
			this.invalidInputs.handler = "";
		},

		multiSelect(key, dataObj, noKey) {
			const index = this.localTask[key].indexOf(dataObj._id);
			if (index === -1) {
				this.localTask[key].push(dataObj._id);
				this.localTask[`${key}No`].push(dataObj[noKey]);
			} else {
				this.localTask[key].splice(index, 1);
				this.localTask[`${key}No`].splice(index, 1);
			}
		},

		updateStage(value) {
			this.localTask.stage = value;
			this.invalidInputs.stage = "";
		},

		updateStatus(value) {
			this.localTask.status = value;
			this.invalidInputs.status = "";
			if (value === "open") {
				// note that there will be a time zone display difference after saving if the user's system time
				// is not in HK time zone
				const dueDate = format(new Date(Date.now() + 2.592e+9), "yyyy-MM-dd"); // 30 days
				this.localTask.dueAt = `${dueDate}T15:59:59.999Z`; // UTC 3:59:59 pm = HKT 11:59:59 pm
				this.localTask.dueAtDT.date = dueDate;
				this.localTask.dueAtDT.time = "23:59:59"; // this is just for display
				this.invalidInputs.dueAtDT.date = "";
				this.invalidInputs.dueAtDT.time = "";
			}
		},

		updateDT(key, keyDT, dateTime, value) {
			this.localTask[keyDT][dateTime] = value;
			this.invalidInputs[keyDT][dateTime] = "";
			if (dateTime === "date") {
				this.localTask[key] = this.localTask[keyDT].time ?
					new Date(`${value}T${this.localTask[keyDT].time}`).toISOString() : value;
			} else {
				this.localTask[key] = this.localTask[keyDT].date ?
					new Date(`${this.localTask[keyDT].date}T${value}`).toISOString() : value;
			}
		},

		// only set invalid, shouldn't set it back to empty string
		// nullish check only, no other validations since they are being taken care of in input event handlers
		dataInvalid() {
			const keys = ["status", "stage", "client", "handler"];
			keys.forEach(key => {
				if (!this.localTask[key]) this.invalidInputs[key] = "invalid";
			});
			if (this.localTask.dueAt) {
				if (!this.localTask.dueAtDT.date) this.invalidInputs.dueAtDT.date = "invalid";
				if (!this.localTask.dueAtDT.time) this.invalidInputs.dueAtDT.time = "invalid";
			} else {
				this.invalidInputs.dueAtDT.date = "invalid";
				this.invalidInputs.dueAtDT.time = "invalid";
			}
			return Object.keys(this.invalidInputs).some(key => {
				if (key === "dueAtDT") {
					return this.invalidInputs.dueAtDT.date === "invalid" || this.invalidInputs.dueAtDT.time === "invalid";
				} else {
					return this.invalidInputs[key] === "invalid";
				}
			});
		},

		async saveTask() {
			this.backBtnTitle = "BACK";
			this.backBtnClass = "btn-custom-light mr-2";
			if (this.dataInvalid()) {
				this.msg = "Please Fix Invalid Fields!";
				this.msgInvalid = true;
			} else {
				const deleteKeys = ["clientName", "handlerName", "quotationRequestsNo", "supplierQuotationsNo", "purchaseOrdersNo",
					"salesQuotationsNo", "salesOrdersNo", "campaignsNo", "createdByName", "updatedByName",
					"dueAtDT", "createdAtDT", "updatedAtDT"];
				const now = new Date().toISOString();
				const task = {...this.localTask};
				if (!task.createdAt) {
					task.createdAt = now;
					task.createdBy = this.$store.state.user.user._id;
				}
				task.updatedAt = now;
				task.updatedBy = this.$store.state.user.user._id;
				deleteKeys.forEach(key => delete task[key]);
				await this.$store.dispatch("contents/updateContent", { key: "tasks", value: task});
				if (this.taskId) {
					this.initTask();
					this.msg = "Previous Changes Saved!";
					this.msgInvalid = false;
				} else {
					// bypassing beforeRouteLeave guard
					this.msg = "Leave Without Saving?";
					await this.$router.push(`/project/task/${task._id}`);
					// need to run initTask after route change since taskId prop was undefined
					this.initTask();
					// vue router reuses this component
					this.msgInvalid = false;
					this.msg = "New Task Created!";
				}
			}
		},

		async deleteTask(bvModalEvent) {
			if (this.password !== this.$store.state.user.user.password) {
				bvModalEvent.preventDefault();
				this.pwInvalid = true;
				if (this.taskNo !== this.task.taskNo) this.taskNoInvalid = true;
			} else if (this.taskNo !== this.task.taskNo) {
				bvModalEvent.preventDefault();
				this.taskNoInvalid = true;
			} else {
				await this.$store.dispatch("contents/deleteContent", { key: "tasks", _id: this.task._id});
				// bypassing beforeRouteLeave guard
				this.msg = "Leave Without Saving?";
				await this.$router.push("/project/task");
			}
		},

		async goBack() {
			if (this.msg === "Leave Without Saving?") {
				await this.$router.push(this.toRoute);
			} else {
				await this.$router.push("/project/task");
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/form";
@import "src/assets/scss/table_overflow";
</style>
